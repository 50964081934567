import store from "@/store";
import {
  createCustomCRM,
  updateCustomCRM,
  deleteCustomCRM,
  updateSettings,
  createDisposition,
  updateDisposition,
  createLevelList,
  createScript,
  createPublicAPIBearerToken,
  deletePublicAPIBearerToken,
  updateLevelList,
  updateScript,
  deleteDisposition,
  createCustomUrl,
  updateCustomUrl,
  deleteCustomUrl,
} from "@/graphql/mutations";
import { getSettings, listPublicAPIBearerToken, listDispositions, getCustomCRM, getCustomsLinks } from "@/graphql/queries";
import { onNewDisposition, onUpdateSettings } from "@/graphql/subscriptions";

import { API, graphqlOperation } from "aws-amplify";

import { Spin } from "view-design";
import { get } from "lodash";
import { deleteRemnantsOfProp } from "@/common/support";
import { i18n } from "@/plugins/language";
import { fetcherAmplify } from "@/libs/fetcher";
import { CUSTOM_LINKS } from "@connectpath/common-frontend";
import { QUICKSIGHT_PRICING_MODE } from "@connectpath/common/src/constants";

if (!window.localStorage.getItem("language")) {
  window.localStorage.setItem("language", i18n.locale === "en-US" ? "en" : "es");
}

export default {
  namespaced: true,
  state: {
    isUpdatingConfig: false,
    configuration: {
      ContactLens: {
        isEnabled: null,
        isAllowed: null,
      },
      Poly: {
        isEnabled: false,
        isAllowed: null,
      },
      Theme: null,
      MultiLevelSeries: "INACTIVE",
      PresentedNotAnswered: {
        Label: null,
        Status: null,
        Duration: null,
      },
      CallDispositions: {
        isEnabled: false,
        allowCustomInput: false,
        isMandatory: false,
        isMultiLevelMandatory: false,
      },
      CustomCRM: {
        isAllowed: false,
        isEnabled: false,
        id: "",
      },
      OutboundMessages: {
        isEnabled: false,
        ContactFlow: "",
      },
      ThirdPartyIntegrations: null,
      DefaultOutboundChannels: {
        EmailChannelId: "",
        SmsChannelId: "",
      },
      DefaultContactFlow: {
        FlowId: null,
        FlowName: null,
      },
      IdleActive: false,
      IdleStatus: "",
      IdleTime: 1,
      LegacyWebhookChannelBehavior: false,
      ComprehendIntegration: false,
      DefaultTaskTemplateId: null,
      QuickSightReadOnlyUser: "",
      QuickSightPricing: true,
      ContactAttributesFilter: [],
    },
    callDispositions: [],
    darkMode: false,
    transitionName: "",
    availableCallDispositions: [],
    language: window.localStorage.getItem("language"),
    availableLanguages: [
      { iso: "en", name: "English" },
      { iso: "es", name: "Spanish" },
      { iso: "pt", name: "Portuguese" },
      { iso: "fr", name: "French" },
      { iso: "af", name: "Afrikaans" },
    ],
    ccpUrlDeepLink: null,
    customLinks: [],
    connectParameters: {},
    emailAliases: [],
  },
  getters: {
    isDarkMode(state) {
      return state.darkMode;
    },
    isCallDispositionEnabled(state) {
      return state.configuration.CallDispositions.isEnabled;
    },
    isAvailableCallDispositions(state) {
      return !!state.availableCallDispositions.length;
    },
    availableCallDispositions(state) {
      return state.availableCallDispositions;
    },
    isMultilevelDispositionEnabled(state) {
      return state.configuration.MultiLevelSeries === "ACTIVE";
    },
    isCustomInputDispositionEnabled(state) {
      return state.configuration.CallDispositions.allowCustomInput;
    },
    currentUserLanguage(state) {
      return state.language || window.localStorage.getItem("language");
    },
    isUpdatingConfig(state) {
      return state.isUpdatingConfig;
    },
    configurations(state) {
      return state.configuration;
    },
    callDispositionAllowCustomInput(state) {
      return state.configuration.CallDispositions.allowCustomInput;
    },
    isOutboundMessagesEnabled(state) {
      return state.configuration.OutboundMessages.isEnabled;
    },
    getCallDispositions(state) {
      return state.callDispositions;
    },
    isContactLensEnabled(state) {
      return !!state.configuration.ContactLens;
    },
    getCcpUrlDeepLink(state) {
      return state.ccpUrlDeepLink;
    },
    getDefaultOutboundChannels(state) {
      return state.configuration?.DefaultOutboundChannels || {};
    },
    getDefaultOutboundEmailId(state) {
      return state.configuration?.DefaultOutboundChannels?.EmailChannelId;
    },
    getDefaultOutboundSmsId(state) {
      return state.configuration?.DefaultOutboundChannels?.SmsChannelId;
    },
    isReportingAllowed(state) {
      if (state.configuration?.Reporting?.isAllowed) {
        return state.configuration.Reporting.isAllowed;
      }
      return false;
    },
    getIdleActive(state) {
      return state.configuration.IdleActive;
    },
    getIdleStatus(state) {
      return state.configuration.IdleStatus;
    },
    getIdleTime(state) {
      return state.configuration.IdleTime;
    },
    getCustomLinks(state) {
      return state.customLinks;
    },
    getLegacyWebhookChannelBehavior(state) {
      return state.configuration.LegacyWebhookChannelBehavior;
    },
    getComprehendIntegration(state) {
      return state.configuration.ComprehendIntegration;
    },
    getConnectParmeters(state) {
      return state.connectParameters;
    },
    getDefaulTaskTemplateId(state) {
      return state.configuration?.DefaultTaskTemplateId || "";
    },
    getQuickSightReadOnlyUserID(state) {
      return state.configuration.QuickSightReadOnlyUser;
    },
    getQuickSightPricing(state) {
      let val = state.configuration.QuickSightPricing;
      if (val === undefined || val == null || val.length <= 0) return true;
      return state.configuration.QuickSightPricing;
    },
    getUserCallDispositions(state) {
      return state.configuration.CallDispositions;
    },
    getEmailAliases: (state) => state.emailAliases ?? [],
    getDefaultOutboundEmailChannel: (state, getters) => {
      const emailWebhooks = store.getters["channel/getEmailWebhooks"];
      if (!emailWebhooks.length) return null;

      if (getters.getDefaultOutboundEmailId) {
        const emailWebhook = emailWebhooks.find((webhook) => webhook.id === getters.getDefaultOutboundEmailId);
        return emailWebhook;
      }

      if (emailWebhooks.length === 1) {
        return emailWebhooks[0];
      }

      return null;
    },
    getContactAttributesFilter(state) {
      return state.configuration.ContactAttributesFilter || [];
    },
    getDefaultContactFlow(state) {
      return state.configuration.DefaultContactFlow;
    },
  },
  mutations: {
    updateOutboundMessages(state, v) {
      state.configuration.OutboundMessages.isEnabled = v;
    },
    removedDisposition(state, v) {
      for (let x = 0; x < state.callDispositions.length; x++) {
        if (state.callDispositions.id == v.id) {
          state.callDispositions.splice(x, 1);
          return false;
        }
      }
    },
    setSettings(state, v) {
      v.MultiLevelSeries = v.MultiLevelSeries ?? "INACTIVE";
      state.configuration = {
        ...v,
      };

      //TODO: Include this in configuration state object
      state.emailAliases = v.EmailAliases;

      const timeout = setTimeout(() => {
        Spin.hide();
        clearTimeout(timeout);
      }, 1000);
    },
    setCallDispositions(state, payload) {
      state.callDispositions = payload;
      const timeout = setTimeout(() => {
        Spin.hide();
        clearTimeout(timeout);
      }, 1000);
    },
    setDarkMode(state, value) {
      state.darkMode = value;
    },
    setTransitionName(state, value) {
      state.transitionName = value;
    },
    initSettings(state) {
      state.isUpdatingConfig = false;
      state.configuration = {
        Theme: null,
        ContactLens: {
          isEnabled: null,
          isAllowed: null,
        },
        PresentedNotAnswered: {
          Label: null,
          Status: null,
          Duration: null,
        },
        CallDispositions: {
          isEnabled: false,
          allowCustomInput: false,
        },
        CustomCRM: {
          isAllowed: false,
          isEnabled: false,
        },
        OutboundMessages: {
          isEnabled: false,
          ContactFlow: "",
        },
        ThirdPartyIntegrations: null,
        DefaultOutboundChannels: {
          EmailChannelId: "",
          SmsChannelId: "",
        },
        IdleActive: false,
        IdleStatus: "",
        IdleTime: 1,
        LegacyWebhookChannelBehavior: false,
        ComprehendIntegration: false,
        DefaultTaskTemplateId: null,
        QuickSightPricing: true,
        QuickSightReadOnlyUser: "",
      };
      state.callDispositions = [];
      state.darkMode = false;
      state.transitionName = "";
      state.language = window.localStorage.getItem("language");
      state.emailAliases = state.emailAliases || [];
    },
    updateState(state, value) {
      state.isUpdatingConfig = value.isUpdatingConfig;
      state.configuration = value.configuration;
      state.callDispositions = value.callDispositions;
      state.darkMode = value.darkMode;
      state.transitionName = value.transitionName;
      state.language = value.language;
    },
    updateCustomCRM(state, value) {
      state.configuration.CustomCRM = value;
    },
    setAvailableCallDispositions(state, value) {
      state.availableCallDispositions = value;
    },
    setLanguage(state, value) {
      state.language = value;
    },
    updateCcpUrlDeepLink(state, payload) {
      state.ccpUrlDeepLink = payload;
    },
    setDefaultOutboundChannels(state, value) {
      state.configuration.DefaultOutboundChannels = value;
    },
    setIdleActive(state, IdleActive) {
      state.configuration.IdleActive = IdleActive;
    },
    setIdleStatus(state, IdleStatus) {
      state.configuration.IdleStatus = IdleStatus;
    },
    setIdleTime(state, IdleTime) {
      state.configuration.IdleTime = IdleTime;
    },
    updateCustomLinks(state, payload) {
      state.customLinks = payload;
    },
    setConnectParameters(state, payload) {
      state.connectParameters = payload;
    },
    setDefaultTaskTemplateId(state, DefaultTaskTemplateId) {
      state.configuration.DefaultTaskTemplateId = DefaultTaskTemplateId;
    },
    setQuickSightPricing(state, payload) {
      state.configuration.QuickSightPricing = payload === QUICKSIGHT_PRICING_MODE.SESSION;
    },
    setEmailAliases(state, payload) {
      state.emailAliases = payload;
    },
    setContactAttributesFilter(state, payload) {
      state.configuration.ContactAttributesFilter = payload;
    },
    setDefaultFlowId(state, payload) {
      state.configuration.DefaultContactFlow = payload;
    },
  },
  actions: {
    updateAvailableCallDispositions({ commit }, dispositions) {
      commit("setAvailableCallDispositions", dispositions);
    },
    setAvailableCallDispositionsByQueue({ commit, getters }, { queueArn }) {
      const callDispositions = getters.getCallDispositions;
      const availableCallDispositions = callDispositions.filter((disposition) => {
        const { Queues } = disposition;
        const queues = Queues.map((queue) => queue.Arn);
        return queues.includes(queueArn);
      });
      commit("setAvailableCallDispositions", availableCallDispositions);
    },
    settingsSubs(context, payload) {
      const observable = API.graphql(
        graphqlOperation(onUpdateSettings, {
          InstanceId: payload,
        })
      );
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateSettings;
          if (newData) {
            context.commit("setSettings", newData);
          }
          return Promise.resolve(newData);
        },
      };

      context.commit("addSubscriptionToList", { id: "settingsSubs", observable, handler }, { root: true });
    },
    dispositionSubs(context, payload) {
      const observable = API.graphql(
        graphqlOperation(onNewDisposition, {
          InstanceId: payload,
        })
      );
      const handler = {
        next: (eventData) => {
          const newDisposition = eventData.value.data.onNewDisposition;
          if (newDisposition) {
            Spin.show();
            let currentDispositions = context.state.callDispositions;
            if (!currentDispositions.find((x) => x.id === newDisposition.id)) {
              currentDispositions.push({
                Name: newDisposition.Name,
                Queues: [...newDisposition.Queues],
                id: newDisposition.id,
              });
              context.commit("setCallDispositions", currentDispositions);
            }
          }
          Spin.hide();
          return Promise.resolve(eventData);
        },
      };

      context.commit("addSubscriptionToList", { id: "dispositionSubs", observable, handler }, { root: true });
    },
    async getConfigs(context, payload) {
      const res = await API.graphql(
        graphqlOperation(getSettings, {
          InstanceId: payload,
        })
      );
      context.commit("setSettings", res.data.getSettings);
      return res;
    },
    async updateConfigs(context, payload) {
      context.state.isUpdatingConfig = true;
      deleteRemnantsOfProp(payload, ["__typename"]);
      try {
        const res = await API.graphql(graphqlOperation(updateSettings, payload));
        context.commit("setSettings", res.data.updateSettings);
        return res;
      } finally {
        context.state.isUpdatingConfig = false;
      }
    },
    async addNewCallDispositions(context, payload) {
      context.state.isUpdatingConfig = true;
      try {
        const res = await API.graphql(graphqlOperation(createDisposition, payload));
        return res;
      } finally {
        context.state.isUpdatingConfig = false;
      }
    },
    async updateSelectedCallDisposition(context, payload) {
      context.state.isUpdatingConfig = true;
      try {
        const res = await API.graphql(graphqlOperation(updateDisposition, payload));
        return res;
      } finally {
        context.state.isUpdatingConfig = false;
      }
    },
    async createNewSeries(context, payload) {
      context.state.isUpdatingConfig = true;
      try {
        const res = await API.graphql(graphqlOperation(createLevelList, payload));
        return res;
      } finally {
        context.state.isUpdatingConfig = false;
      }
    },
    async createNewScript(context, payload) {
      context.state.isUpdatingConfig = true;
      try {
        const res = await API.graphql(graphqlOperation(createScript, payload));
        return res;
      } finally {
        context.state.isUpdatingConfig = false;
      }
    },
    createNewToken(context, payload) {
      context.state.isUpdatingConfig = true;
      context.state.isUpdatingConfig = false;
      try {
        const response = API.graphql(graphqlOperation(createPublicAPIBearerToken, payload));
        context.state.isUpdatingConfig = false;
        return response;
      } catch (e) {
        context.state.isUpdatingConfig = false;
        return e;
      }
    },
    deleteToken(context, payload) {
      return API.graphql(
        graphqlOperation(deletePublicAPIBearerToken, {
          input: {
            id: payload.id,
            InstanceId: payload.InstanceId,
            Username: payload.Username,
          },
        })
      );
    },
    getTokenData(context, payload) {
      return API.graphql(
        graphqlOperation(listPublicAPIBearerToken, {
          InstanceId: payload.InstanceId,
          Username: payload.Username,
        })
      );
    },
    async updateSeriesData(context, payload) {
      context.state.isUpdatingConfig = true;
      try {
        const res = await API.graphql(graphqlOperation(updateLevelList, payload));
        return res;
      } finally {
        context.state.isUpdatingConfig = false;
      }
    },
    async updateScriptData(context, payload) {
      context.state.isUpdatingConfig = true;
      try {
        const res = await API.graphql(graphqlOperation(updateScript, payload));
        return res;
      } finally {
        context.state.isUpdatingConfig = false;
      }
    },

    async fetchCallDipositions({ commit }, payload) {
      const res = await API.graphql(graphqlOperation(listDispositions, payload));
      commit("setCallDispositions", get(res, "data.listDispositions.items", []));
      return res;
    },
    async deleteCallDisposition(context, payload) {
      const res = await API.graphql(graphqlOperation(deleteDisposition, payload));
      return res;
    },
    async createCustomCRM({ commit }, payload) {
      const res = await API.graphql(graphqlOperation(createCustomCRM, payload));
      commit("updateCustomCRM", {
        isEnabled: true,
        isAllowed: true,
        id: res.data.createCustomCRM.id,
      });
      return res;
    },
    async getCustomCRM(_, payload) {
      const res = await API.graphql(graphqlOperation(getCustomCRM, payload));
      return res;
    },
    async updateCustomCRM(_, payload) {
      const res = await API.graphql(graphqlOperation(updateCustomCRM, payload));
      return res;
    },
    async deleteCustomCRM({ commit }, payload) {
      const res = await API.graphql(graphqlOperation(deleteCustomCRM, payload));
      commit("updateCustomCRM", {
        isEnabled: false,
        isAllowed: true,
        id: null,
      });
      return res;
    },
    addCcpUrlDeepLink(context, value) {
      context.commit("updateCcpUrlDeepLink", value);
    },

    async updateDefaultOutboundChannels({ dispatch, commit }, { DefaultOutboundChannels, InstanceId }) {
      try {
        await dispatch("updateConfigs", {
          input: {
            InstanceId,
            DefaultOutboundChannels,
          },
        });
        commit("setDefaultOutboundChannels", DefaultOutboundChannels);
      } catch (error) {
        console.error(error);
      }
    },
    setInactivitySettings({ commit }, { IdleActive, IdleStatus, IdleTime }) {
      commit("setIdleActive", IdleActive);
      commit("setIdleStatus", IdleStatus);
      commit("setIdleTime", IdleTime);
    },

    async saveInactivitySettings({ dispatch }, { InstanceId, IdleActive, IdleStatus, IdleTime }) {
      try {
        await dispatch("updateConfigs", {
          input: {
            InstanceId,
            IdleActive,
            IdleStatus,
            IdleTime,
          },
        });
        dispatch("setInactivitySettings", { IdleActive, IdleStatus, IdleTime });
        dispatch("ui/messageEndpointSuccess", { message: `${i18n.t("settings.inactivitySettingsUpdated")}` }, { root: true });
      } catch (error) {
        dispatch("ui/messageEndpointError", { message: `${i18n.t("settings.inactivitySettingsError")}` }, { root: true });
        console.error(`saveInactivitySettings -> `, error);
      }
    },

    async createCustomLink(context, { name, url, type, browserTab, scope, awsAgentId }) {
      try {
        return await fetcherAmplify().graphql({
          query: createCustomUrl,
          variables: {
            input: {
              Name: name,
              BaseUrl: url,
              Type: type,
              BrowserTab: browserTab,
              Scope: scope,
              AWSAgentID: awsAgentId,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async updateCustomLinks(context, { id, name, url, type, browserTab, scope, awsAgentId }) {
      try {
        return await fetcherAmplify().graphql({
          query: updateCustomUrl,
          variables: {
            input: {
              Id: id,
              Name: name,
              BaseUrl: url,
              Type: type,
              BrowserTab: browserTab,
              Scope: scope,
              AWSAgentID: awsAgentId,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },

    async fetchCustomLinks(context, { awsAgentId }) {
      try {
        const response = await fetcherAmplify().graphql({
          query: getCustomsLinks,
        });
        const customLinksFilteredByScope = response?.listCustomUrl.filter(
          (link) =>
            !link.Scope ||
            link.Scope === CUSTOM_LINKS.GLOBAL_SCOPE ||
            (CUSTOM_LINKS.PRIVATE_SCOPE && link.AWSAgentID === awsAgentId)
        );
        return customLinksFilteredByScope.map((item) => {
          return {
            id: item.Id,
            name: item.Name,
            url: item.BaseUrl,
            type: item.Type,
            browserTab: item.BrowserTab,
            scope: item.Scope,
            awsAgentId: item.AWSAgentID,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },

    async deleteCustomLink(context, { id }) {
      try {
        return await fetcherAmplify().graphql({
          query: deleteCustomUrl,
          variables: {
            input: {
              Id: id,
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    appendCustomLinks({ commit }, payload) {
      commit("updateCustomLinks", payload);
    },

    async updateDefaultTaskTemplateId({ commit, dispatch }, { InstanceId, DefaultTaskTemplateId }) {
      try {
        await dispatch("updateConfigs", {
          input: {
            InstanceId,
            DefaultTaskTemplateId,
          },
        });
        commit("setDefaultTaskTemplateId", DefaultTaskTemplateId);
        dispatch(
          "ui/messageEndpointSuccess",
          { message: `${i18n.t("settings.defaultTaskTemplateUpdateSuccess")}` },
          { root: true }
        );
      } catch (error) {
        console.error(error);
        dispatch(
          "ui/messageEndpointError",
          { message: `${i18n.t("settings.defaultTaskTemplateUpdateError")}` },
          { root: true }
        );
      }
    },
    resetDefaultTaskTemplateId({ commit }) {
      commit("setDefaultTaskTemplateId", "");
    },
    updateEmailAliases({ commit }, payload) {
      commit("setEmailAliases", payload);
    },
    updateContactAttributesFilter({ commit }, contactAttributesFilter) {
      commit("setContactAttributesFilter", contactAttributesFilter);
    },
    async updateDefaultFlowId({ commit }, { InstanceId, FlowId, FlowName }) {
      try {
        const res = await fetcherAmplify().graphql({
          query: updateSettings,
          variables: {
            input: {
              InstanceId,
              DefaultContactFlow: {
                FlowId,
                FlowName,
              },
            },
          },
        });
        commit("setDefaultFlowId", {
          FlowId,
          FlowName,
        });
        return res;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
